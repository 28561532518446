<template>
  <div style="width:100%;">
    <v-card width="400" style="margin-left:auto;margin-right:auto;" outlined>
      <v-card-title>
        <h2>{{ $t("register.register") }}</h2>
      </v-card-title>
      <v-card-text>
        <v-form @submit="register">
          <v-text-field type="text" :label="$t('register.email')" v-model="email"></v-text-field>
          <v-text-field type="password" :label="$t('register.password')" v-model="password"></v-text-field>
          <v-text-field type="password" :label="$t('register.repeat_password')" v-model="password_repeat"></v-text-field>
          <v-btn type="submit" depressed>{{ $t("register.register") }}</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="openLogin">{{ $t("register.or_want_to_login") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { auth } from "../schmucklicloud";
import { Notifications } from "../notification";

export default {
  data() {
    return {
      email: "",
      password: "",
      password_repeat: ""
    };
  },
  methods: {
    openLogin() {
      this.$router.replace("/login");
    },
    async register(e) {
      e.preventDefault();

      if (this.password === this.password_repeat) {
        var response = await auth.registerEmailPassword(
          this.email,
          this.password
        );
        if (response.isOK) {
          Notifications.show(
            "You have been registered now. Please check your inbox now."
          );
          this.$router.replace("/login");
        } else {
          Notifications.show(response.message);
        }
      }
    }
  }
};
</script>

<style>
</style>